@import url('https://fonts.googleapis.com/css?family=Josefin+Sans');

.wpb_wrapper {
  max-width: 100%;
  position: relative;
}

.text_footer {
  align-items: center;
  justify-content: center;
  display: flex;
  max-width: 91.33333%;
  flex-wrap: wrap;
  margin-right: -15px;
  max-width: 91.33333%;
  min-height: 1px;
  padding: 4px 0;
  position: relative;
  width: 100%;
}

/* .logo{    
  flex: 0 0 16.66667%;
  justify-content: flex-end;
  max-width: 16.66667%;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  text-align: right;
  width: 100%;
} */

.logo_black_footer {
  text-align: right;
  border-style: none;
  height: auto;
  max-width: 90px;
  opacity: 1;
  transition: opacity .4s;
  transition-delay: 0ms;
  vertical-align: middle;
  width: 80%;
}

.copyright {
  flex: 0 0 16.66667%;
  max-width: 60%;
  min-height: 1px;
  padding: 0;
  position: relative;
  width: 100%;
}

.copyright p {
  color: rgba(0, 0, 0, .6);
  font-family: century_gothic;
  font-size: 15px;
  margin: 0;
  max-width: 100%;
  text-align: center;
}

.lien {
  flex: 0 0 58.33333%;
  max-width: 50%;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 0;
  position: relative;
  width: 100%;
}

.lien p {
  color: #000;
  display: flex;
  flex-wrap: wrap;
  font-family: century_gothic;
  font-size: 15px;
  justify-content: center;
  margin: 0;
  max-width: 100%;
}

.lien p a {
  font-family: century_gothic;
  background-color: transparent;
  text-decoration: none;
  color: rgba(0, 0, 0, .6);
  display: inline-block;
  font-size: 15px;
}

.lien p span {
  color: #000;
  font-family: century_gothic;
  font-size: 15px;
  margin: 0 8px;
}

.social {
  -webkit-box-flex: 0;
  max-width: 8.33333%;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}

.list_social {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.list_social li {
  list-style: none;
  margin-right: 5px;
}

.list_social li a {
  list-style: none;
  background-color: transparent;
  color: rgba(0, 0, 0, .6);
  text-decoration: none;
}

